<template>
  <div class="box-layout">
    <HeaderFiveEpitonet>
      <img slot="logo" src="../../assets/img/logo/global-logo.jpg" />
    </HeaderFiveEpitonet>

    <!-- Start Portfolio Details Area -->
    <div class="rf-portfolio-details section-ptb-xl">
      <v-container class="container">
        <v-row>
          <v-col sm="6" cols="12" class="pr--60 pr-md-15 pr-sm-15">
            <div class="rp-port-thumb">
              <div class="port-single-thumb">
                <img
                  src="../../assets/img/567x600px/567x600px-Asztalos.jpg"
                  alt="portfolio"
                />
              </div>

            

            </div>
          </v-col>
          <v-col sm="6" cols="12">
            <div class="rp-port-details-inner sticky-top">
              <div class="content">
                <h3>Bútorasztalos munkák</h3>
                <p>
                  
                </p>
                <h4>Egyedi bútorkészítés :</h4>
                <p>
Lakóingatlanok, lakóparkok, irodák, üzletek, intézmények részére készítünk bútorokat, az aktuális bútortrendeknek megfelelően, vagy a megrendelőink elképzelései, tervei alapján. Törekszünk mindig a legfrissebb trendeket figyelembe venni, hogy praktikus, és megjelenésében is esztétikus bútorokat tudjunk alkotni. 

Kiváló minőségű, bútorokat készítünk, melyhez a legjobb szerelvény márkákat használjuk. 
                </p>
                
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Portfolio Details Area -->

    <Footer />
  </div>
</template>

<script>
  import HeaderFiveEpitonet from "../../components/header/HeaderFourEpitonet";
  import Footer from "../../components/footer/Footer";
  export default {
    components: {
      HeaderFiveEpitonet,
      Footer,
    },
    data() {
      return {};
    },
  };
</script>

<style lang="scss" scoped>
  .portfolio-details-page .-rn-footer {
    background: #f9f9f9;
  }
</style>
